import React, { useState, useEffect } from 'react';
import { Card, Stack, DataTable, Modal, TextContainer, Button, Text, SkeletonBodyText } from '@shopify/polaris';
import { useShopProvider } from '../../components/ShopProvider';
import { currencyFormatter, numberFormatter, percentFormatter } from '../../utils/formatters';
import { useChatSessionAggregates } from '../../hooks/useChatSessions';
import { DashboardKPI } from '../Dashboard/DashboardHeader';
import "./styles.css";

import { useHistory } from 'react-router-dom';

export const ChatKPIObject = (currencyCode) => {
  return ({
      sessions_count: {
          name: 'Total Chat Sessions ',
          value: 0,
          render: (value) => numberFormatter().format(value),
          isAgg: true,
          display: true,
          description: 'Total number of chat sessions',
          comparisonColor: (ratio) => ratio > 1.0 ? 'success' : ratio < 1.0 ? 'critical' : null,
      },
      average_messages: {
        name: 'Average Messages',
        value: 0,
        render: (value) => numberFormatter().format(value),
        display: true,
        description: 'Average number of messages per chat session',
        comparisonColor: (ratio) => ratio > 1.0 ? 'success' : ratio < 1.0 ? 'critical' : null,
      },        
      orders_total: {
          name: 'Total Sales',
          value: 0,
          render: (value) => currencyFormatter(currencyCode).format(value),
          isAgg: true,
          display: true,
          description: 'Total sales generated from chat sessions',
          comparisonColor: (ratio) => ratio > 1.0 ? 'success' : ratio < 1.0 ? 'critical' : null,
      },
      orders_count: {
          name: 'Number of Orders',
          value: 0,
          render: (value) => numberFormatter().format(value),
          isAgg: true,
          display: true,
          description: 'Number of orders generated from chat sessions',
          comparisonColor: (ratio) => ratio > 1.0 ? 'success' : ratio < 1.0 ? 'critical' : null,
      },
      conversion_rate: {
          name: 'Conversion Rate',
          value: 0,
          render: (value) => percentFormatter().format(value),
          display: true,
          description: 'ratio of chat sessions resulting in a sale vs all chat sessions',
          comparisonColor: (ratio) => ratio > 1.0 ? 'success' : ratio < 1.0 ? 'critical' : null,
      },      
      order_per_call: {
          name: 'Sales per Session',
          value: 0,
          render: (value) => currencyFormatter(currencyCode).format(value),
          display: true,
          description: 'Average sales generated on a chat session',
          comparisonColor: (ratio) => ratio > 1.0 ? 'success' : ratio < 1.0 ? 'critical' : null,
      },
      average_order: {
          name: 'AOV',
          value: 0,
          render: (value) => currencyFormatter(currencyCode).format(value),
          display: true,
          description: 'Average Order Value for orders generated on chat sessions',
          comparisonColor: (ratio) => ratio > 1.0 ? 'success' : ratio < 1.0 ? 'critical' : null,
      }
  })
};

const ChatDashboard = ({ filter }) => {
  const { shopDataLoading, shopData, shopOrigin, } = useShopProvider();
  const history = useHistory();
  const { aggregates, loading } = useChatSessionAggregates(shopOrigin,['archived'] ,filter);
  const [kpis, setKpis] = useState(ChatKPIObject())


  useEffect(() => {
    if (shopData && aggregates) {
      const kpis = ChatKPIObject(shopData.currencyCode)
      kpis.sessions_count.value = aggregates.totalSessions
      kpis.orders_total.value = aggregates.totalOrderAmount
      kpis.orders_count.value = aggregates.totalOrders
      kpis.conversion_rate.value = aggregates.totalSessions ? (aggregates.totalOrders / aggregates.totalSessions) : null
      kpis.order_per_call.value = aggregates.totalSessions ? (aggregates.totalOrderAmount / aggregates.totalSessions) : null
      kpis.average_order.value = aggregates.totalOrders ? (aggregates.totalOrderAmount / aggregates.totalOrders) : null
      kpis.average_messages.value = aggregates.totalSessions ? (aggregates.totalMessages / aggregates.totalSessions) : null

      kpis.sessions_count.previousValue = aggregates.totalSessionsPrevious
      kpis.orders_total.previousValue = aggregates.totalOrderAmountPrevious
      kpis.orders_count.previousValue = aggregates.totalOrdersPrevious
      kpis.conversion_rate.previousValue = aggregates.totalSessionsPrevious ? (aggregates.totalOrdersPrevious / aggregates.totalSessionsPrevious) : null
      kpis.order_per_call.previousValue = aggregates.totalSessionsPrevious ? (aggregates.totalOrderAmountPrevious / aggregates.totalSessionsPrevious) : null
      kpis.average_order.previousValue = aggregates.totalOrdersPrevious ? (aggregates.totalOrderAmountPrevious / aggregates.totalOrdersPrevious) : null
      kpis.average_messages.previousValue = aggregates.totalSessionsPrevious ? (aggregates.totalMessagesPrevious / aggregates.totalSessionsPrevious) : null
      setKpis(kpis)
    }    
  }, [filter, aggregates,shopData])

  return (
    
      <Card>
        <div className='chat-dashboard-header'>
                {Object.keys(kpis).filter(kpi => kpis[kpi].display).map(kpi => <DashboardKPI key={kpi} kpi={kpis[kpi]} isLoading={loading || shopDataLoading} />)}
            </div>
      </Card>
  )
}

export default ChatDashboard;
