import React, { useState, useEffect } from "react";

import {
  Banner,
  Layout,
  Text,
  Stack,
  Button
} from '@shopify/polaris';
import { RefreshMajor } from "@shopify/polaris-icons";
import { AdjustMinor } from "@shopify/polaris-icons";
import { useHistory, Link } from "react-router-dom";

import AdminPage from "../../components/AdminPage";
import AppPage from "../../components/AppPage";
import ChatKPIDashboard from "./ChatDashboard";
import { useShopProvider } from "../../components/ShopProvider";
import CallHistoryFilter from "../CallHistory/components/CallHistoryFilter";
import { DateRange } from '../Dashboard/useDateRange';
import useExportChatSessions from "../../hooks/useExportChatSessions";
import useChatSessions from "../../hooks/useChatSessions";

const defaultFilter = {
	hostId: null,
	range: DateRange.last30,
}

const ChatDashBoardPage = () => {
  const history = useHistory();
  const [filter, setFilter] = useState(defaultFilter);
  const { shopOrigin, hosts, chatConfig  } = useShopProvider(); 
  const {getSessions, sessions, loading} = useChatSessions(shopOrigin, ['archived'], 20, filter)
  const {exportChatSessions, isExportInProgress} = useExportChatSessions(getSessions, shopOrigin, filter, hosts)

  const onRefresh = () => {
    setFilter({...filter});
  }

  return (
    <AppPage
      title={<>Chat Dashboard</>}      
    >
      <Layout>
        {!chatConfig?.enabled && <Layout.Section>
          <Banner status='warning'>
            <Text>Client chat feature is disabled</Text>
          </Banner>
        </Layout.Section>}
        <Layout.Section>
        <Stack distribution='trailing'>
          <CallHistoryFilter
            onChangeFilter={setFilter}
            onRefresh={onRefresh}
            onExport={sessions?.length ? exportChatSessions : null}
            isExportInProgress={isExportInProgress}
            defaultFilter={defaultFilter}
            hideSearch={true}
            hideEventTypes={true}
          />          
        </Stack>
        </Layout.Section>
        <Layout.Section>
          <ChatKPIDashboard filter={filter} />
        </Layout.Section>        
      </Layout>
    </AppPage>
  )
}

export default function () {
  return (
    <AdminPage>
      <ChatDashBoardPage />
    </AdminPage>
  )
}
