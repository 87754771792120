import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import Moment  from 'moment-timezone';
import { csvBuilder, csvLoader } from '../utils/csvProcessor';
import { useRef, useState } from 'react';


const EXPORT_STATUS = {
  SUCCEED: 'Succeed',
  PENDING: 'Pending',
  NO_DATA: 'No data to export',
  ERROR: 'Something went wrong - '
}

const tableColumnNames = () => {
  let CSVTableHeaders = [
      'Shop Id',
      'Date and time',
      'Client name',
      'Client email',
      'Messages count',
      'Duration (min)',
      'Chat status',
      'Host Id',
      'Host name',
      `Sales amount`,
      'Currency',
  ];
  return [CSVTableHeaders, ...['\n']];
}

const mapChatToCsvTable = (doc,hosts) => {
  const chatSessionData = doc.data();
  const host = chatSessionData.hostId ? hosts?.find(host => host.id === chatSessionData.hostId) : null;
  return [
      chatSessionData.shopId.toString(),
      chatSessionData.createdAt.toDate(),
      chatSessionData.customerName,
      chatSessionData.customerEmail,
      chatSessionData.messageCounter,
      chatSessionData.closedAt ? Math.ceil(Moment(chatSessionData.closedAt.toDate()).diff(Moment(chatSessionData.createdAt.toDate()), 'minutes')) : null,
      chatSessionData.status,
      chatSessionData.hostId,
      host ? `${host.firstName} ${host.lastName}` : '',
      chatSessionData.totalOrderAmount,
      chatSessionData.totalOrderAmount
          ? chatSessionData.totalOrderAmountCurrency || chatSessionData.orders[0].currency
          : null,
  ];
}

const useExportChatSessions = (getChatSessions, shopId, filter, hosts) => {
  const csvFileRef = useRef('');

  const [exportStatus, setExportStatus] = useState(null);
  const [exportError, setExportError] = useState(null);
  const [isExportInProgress, setIsExportInProgress] = useState(false);

  const toCsvConverter = (docs) => {
    const dataRows = docs.map(doc => {
      return mapChatToCsvTable(doc, hosts);
    })
    return csvBuilder(dataRows);
  }

  const csvProcessor = (docs, next) => {
    const csvBatch = toCsvConverter(docs);
    csvFileRef.current += csvBatch;
    if (!next) {
      if (!csvFileRef.current) {
        setExportStatus(EXPORT_STATUS.NO_DATA);
      } else {
        csvFileRef.current = tableColumnNames() + csvFileRef.current;
        setExportStatus(EXPORT_STATUS.SUCCEED);
        csvLoader(csvFileRef.current,`${shopId}_`);
      }
      setIsExportInProgress(false);
    } else {
      next().then(({ docs, next }) => {
        csvProcessor(docs, next);
      }).catch((error) => {
        setExportStatus(EXPORT_STATUS.ERROR);
        setExportError(error);
        setIsExportInProgress(false);
      });
    }
  }

  const exportChatSessions = () => {
    csvFileRef.current = '';
    setExportError(null);
    setExportStatus(EXPORT_STATUS.PENDING);
    setIsExportInProgress(true);

    firebase.analytics().logEvent('admin.export_chat_sessions', { range: filter.range?.id });

    getChatSessions(shopId, 2)
      .then(({ docs, next }) => {
        csvProcessor(docs, next);
      })
      .catch((error) => {
        setExportStatus(EXPORT_STATUS.ERROR);
        setExportError(error);
        setIsExportInProgress(false);
      });
  }

  return ({
    isExportInProgress,
    exportStatus,
    exportError,
    exportChatSessions
  })
}

export default useExportChatSessions;
